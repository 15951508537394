<!--
 * @Description:: 请补充填写
 * @Version: 请配置版本号
 * @Author: xiaobai
 * @Date: 2021-06-11 09:48:44
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-11-04 09:17:12
 * @FilePath: \sipingnongzhuan2\src\views\xxgk\lsyg.vue
-->
<template>
  <div>
    <div
      style="background: rgb(255, 255, 255);
    max-width: 1160px;
    margin: 0px auto !important;"
      class="mt-10 development_historys">
      <div
        class="development_history_a"
        v-for="(item,index) in historyList"
        :key="index">
        <div class="left_box">
          <span
            class="left_text">{{item.year}}</span>
        </div>
        <div
          class="right_box">
          <div
            class="right_text"
            v-html="item.content">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      historyList: [
        {
          year: '2023',
          content:
            '<p style="undefined"><span style="undefined">共青团活动<o:p></o:p></span></p><p><span style="undefined"><o:p>&nbsp;</o:p></span></p><p style="undefined"><span style="undefined">3</span><span style="undefined">月28日，中央宣传部、成立70周年。<o:p></o:p></span></p>'
        },
        {
          year: '2022',
          content:
            '<p style="undefined"><span style="undefined">评选表彰活动<o:p></o:p></span></p><p><span style="undefined"><o:p>&nbsp;</o:p></span></p><p style="undefined"><span style="undefined">3</span><span style="undefined">中国成立70周年。<o:p></o:p></span></p>'
        },
        {
          year: '2021',
          content:
            '<p style="undefined"><span style="undefined">第七届<o:p></o:p></span></p><p><span style="undefined"><o:p>&nbsp;</o:p></span></p><p style="undefined"><span style="undefined">3</span><span style="undefined">见贤思齐、德行天下<o:p></o:p></span></p>'
        }
      ]
    }
  },
  mounted() {
    // this.getHistoryList()
  },
  methods: {
    getHistoryList() {
      axios.post('/home/development/path', {}).then(res => {
        this.historyList = res.data.data.developmentPathList
      })
    }
  }
}
</script>

<style>
</style>